<template>
  <div>
    <div
      class="event-detail seemore"
      v-if="EventDetail && Object.keys(EventDetail).length"
    >
      <div class="content first-banner__content">
        <div class="first-banner">
          <picture>
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/seemore/banner_mob.jpg`"
              media="(max-width: 767px)"
            />
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/seemore/banner_tab.jpg`"
              media="(max-width: 1219px)"
            />
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/seemore/banner.jpg`"
              media="(min-width: 1220px)"
            />
            <img
              class="first-banner__image"
              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/seemore/banner.jpg`"
            />
          </picture>
          <div class="first-banner__text">
            <div class="first-banner__title">
              Ранняя и полная кардиоренальная защита <br />
              пациента с СД 2 типа вместе с Форсига
            </div>
            <div class="first-banner__description">
              Иногда, чтобы открыть новые перспективы, нужно поменять точку
              зрения и увидеть реальность с другого ракурса.
            </div>
            <div
              class="button first-banner__button button_blue d-none d-xl-flex"
              @click="toRegister(true)"
              v-if="!Object.keys(user).length"
            >
              Зарегистрироваться
            </div>
            <div
              class="button first-banner__button button_blue d-none d-xl-flex"
              @click="openIframe()"
              v-else
            >
              Смотреть
            </div>
            <div
              class="button first-banner__button button_red d-md-none"
              @click="toRegister(true)"
              v-if="!Object.keys(user).length"
            >
              Зарегистрироваться
            </div>
            <div
              class="button first-banner__button button_red d-md-none"
              @click="openIframe()"
              v-else
            >
              Смотреть
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="features-grid">
          <div class="features-grid__container">
            <div class="features-grid__item blue-item">
              <div class="features-grid__item-title blue-item">В 5 раз</div>
              <div class="features-grid__item-descr">
                Увеличение риска развития ХСН у пациентов с СД2<sup
                  style="color: inherit"
                  >1</sup
                >
              </div>
            </div>
            <div class="features-grid__item red-item">
              <div class="features-grid__item-title red-item">24%</div>
              <div class="features-grid__item-descr">
                Пациентов с СД2 умерли от ХСН<sup style="color: inherit">2</sup>
              </div>
            </div>
            <div class="features-grid__item green-item">
              <div class="features-grid__item-title green-item">У 58%</div>
              <div class="features-grid__item-descr">
                Пациентов с СД2 развивается ХБП<sup style="color: inherit"
                  >3</sup
                >
              </div>
            </div>
          </div>
        </div>
        <div class="event-detail__about-description mt-16 mb-6">
          Встречайте невероятное событие года вместе с ведущими экспертами
          России и Китая 18 ноября 2023 г.
        </div>
        <!-- <div
          class="event-detail__warning d-flex mt-6"
          v-if="
            (timerResult &&
              Object.keys(this.timerResult).length &&
              ((!timerResult.days && !timerResult.hours) ||
                (!timerResult.days && timerResult.hours < 8))) ||
            !Object.keys(this.timerResult).length
          "
        >
          <svg
            class="mr-2"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16ZM30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16ZM15.3595 23.5C14.8366 23.5 14.5 23.066 14.5 22.5553V14.7511C14.5 14.2404 14.8366 13.8064 15.3595 13.8064H16.6144C17.1373 13.8064 17.5 14.2404 17.5 14.7511V22.5553C17.5 23.066 17.1373 23.5 16.6144 23.5H15.3595ZM16 8C16.8284 8 17.5 8.67157 17.5 9.5C17.5 10.3284 16.8284 11 16 11C15.1716 11 14.5 10.3284 14.5 9.5C14.5 8.67157 15.1716 8 16 8Z"
              fill="#fff"
            />
          </svg>
          <span>
            Коллеги, если у вас возникают проблемы с регистрацией, авторизацией,
            а также проблемы с подключением к трансляции, просим вас обращаться
            в тех поддержку по номеру телефона:
            <a href="tel:88002202014">8-800-220-20-14</a>.<br />
            Обращаем внимание, что техническая поддержка доступна только
            18&nbsp;ноября&nbsp;с&nbsp;09:30&nbsp;до&nbsp;13:30&nbsp;по&nbsp;МСК
          </span>
        </div> -->
        <div class="event-detail__timer mb-0 mt-md-8">
          <!-- <div class="event-detail__timer-title">
            {{
              !Object.keys(timerResult).length
                ? "Смотреть запись вебинара"
                : timerResult.months
                
            }}
          </div> -->
          <!-- <div
            class="event-detail__timer-date mt-sm-4"
            v-if="timerResult.months"
          >
            {{ $m(EventDetail.date).format("DD MMMM") }}
            в
            {{
              $m
                .utc(EventDetail.date)
                .utcOffset(+3)
                .subtract(10, "minutes")
                .format("HH:mm")
            }}
            (МСК)
          </div> -->
          <div class="event-detail__timer-title d-xl-none">
            Мероприятие начнется через:
          </div>
          <div
            v-if="Object.keys(timerResult).length"
            class="event-detail__timer-row"
          >
            <div class="event-detail__timer-item" v-if="timerResult.months">
              <div class="timer__value">{{ timerResult.months }}</div>
              <div class="timer__text">Месяцев</div>
            </div>
            <div class="event-detail__timer-item" v-if="timerResult.days > 0">
              <div class="timer__value">{{ timerResult.days }}</div>
              <div class="timer__text">
                {{  helpers.getNoun(timerResult.days, "день", "дня", "дней") }}
              </div>
            </div>
            <div class="event-detail__timer-item" v-if="timerResult.hours > 0">
              <div class="timer__value">{{ timerResult.hours }}</div>
              <div class="timer__text">
                {{  helpers.getNoun(timerResult.hours, "час", "часа", "часов") }}
              </div>
            </div>
            <div
              class="event-detail__timer-item"
              v-if="timerResult.minutes > 0"
            >
              <div class="timer__value">{{ timerResult.minutes }}</div>
              <div class="timer__text">
                {{  helpers.getNoun(timerResult.minutes, "минуту", "минуты", "минут") }}
              </div>
            </div>
            <div
              class="event-detail__timer-item"
              v-if="timerResult.seconds > 0"
            >
              <div class="timer__value">{{ timerResult.seconds }}</div>
              <div class="timer__text">
                {{
                   helpers.getNoun(timerResult.seconds, "секунду", "секунды", "секунд")
                }}
              </div>
            </div>
          </div>
          <div
            v-if="!Object.keys(timerResult).length"
            @click="openIframe()"
            class="event-detail__translation button button_white-blue px-6"
          >
            Смотреть
          </div>
          <div class="translation-counter">
            <div class="translation-counter__num">3 354</div>
            <div class="translation-counter__text">
              Врачей <br />
              уже с нами
            </div>
            <div class="translation-counter__img">
              <img
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/seemore/count-img.png`"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="event-detail__date-info">
          <div class="event-detail__date-text">
            <div class="event-detail__date-item" v-if="EventDetail.date">
              <div class="event-detail__date-item-title">Дата и время</div>
              <div class="event-detail__date-item-value">
                18 ноября, 10:00 – 14:30 (мск)
              </div>
            </div>
            <div class="event-detail__date-item" v-if="EventDetail.place">
              <div class="event-detail__date-item-title">Место</div>
              <div
                class="event-detail__date-item-value"
                v-html="EventDetail.place"
              ></div>
            </div>
            <div class="event-detail__date-item" v-if="EventDetail.place">
              <div class="event-detail__date-item-title">Формат</div>
              <div class="event-detail__date-item-value">
                {{
                  EventDetail.types[0].name +
                  " " +
                  "\/" +
                  " " +
                  EventDetail.types[1].name
                }}
              </div>
            </div>
            <div
              class="button event-detail__date-button button_blue"
              @click="toRegister(true)"
              v-if="!Object.keys(user).length"
            >
              Зарегистрироваться
            </div>
          </div>
        </div>
        <div class="d-flex mb-8 d-md-none">
          <div
            class="button button_blue d-inline-flex mr-4"
            @click="scrollTo('experts')"
          >
            Эксперты
          </div>
          <div
            class="button button_red d-inline-flex"
            @click="scrollTo('programm')"
          >
            Программа мероприятия
          </div>
        </div>
        <div class="second-banner">
          <div class="second-banner__text">
            <div class="second-banner__title">Что такое “Видеть больше”?</div>
            <div class="second-banner__description">
              Вы можете дать множество версий, и каждая из них будет верной.
              Видеть больше – это грандиозное событие, на котором мы обсудим
              актуальные вопросы и найдем необходимые ответы.
            </div>
            <div class="second-banner__button-container">
              <div
                class="button second-banner__button button_blue"
                @click="copyLink"
              >
                Пригласить коллегу
              </div>
              <div
                class="button second-banner__button button_blue d-none d-xl-flex"
                @click="scrollTo('programm')"
              >
                Программа мероприятия
              </div>
            </div>
          </div>
          <picture>
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/seemore/banner-2_mob.jpg`"
              media="(max-width: 767px)"
            />
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/seemore/banner-2_tab.jpg`"
              media="(max-width: 1219px)"
            />
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/seemore/banner-2.jpg`"
              media="(min-width: 1220px)"
            />
            <img
              class="second-banner__image"
              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/seemore/banner-2.jpg`"
            />
          </picture>
        </div>
        <DefaultSlider
          :color="'#2186AF'"
          :title="'Наши эксперты'"
          :settings="settingsSlider"
          id="experts"
        >
          <template #items>
            <div
              class="slide-lector"
              v-for="(lector, ind) in lectors"
              :key="ind"
            >
              <div class="slide-lector__image-container">
                <img class="slide-lector__image" :src="lector.image" />
              </div>
              <div class="slide-lector__tag" v-html="lector.tag"></div>
              <div class="slide-lector__name" v-html="lector.name"></div>
              <div
                class="slide-lector__description"
                v-html="lector.description"
              ></div>
            </div>
          </template>
        </DefaultSlider>
        <div class="third-banner">
          <div class="third-banner__text">
            <div class="third-banner__title">Линонг Цзи</div>
            <div class="third-banner__description">
              <div class="mb-4">
                Директор Диабетического центра Пекинского университета и
                директор отделения эндокринологии и обмена веществ Народной
                больницы Пекинского университета в Китае.
              </div>

              <div class="mb-4" :class="{ 'd-none d-xl-block': hideText }">
                Профессор Цзи является председателем Международной диабетической
                федерации Западно-Тихоокеанского региона (IDF-WPR),
                вице-президентом Китайской ассоциации эндокринологов (CEA),
                вице-президентом Китайского общества инсульта, президентом
                Китайского гериатрического общества, президентом Общества
                эндокринологов и метаболизма. Входит в состав исполнительного
                совета Азиатской ассоциации по изучению диабета (AASD).
              </div>

              <div :class="{ 'd-none d-xl-block': hideText }">
                Главный редактор Китайского диабетического журнала.
              </div>
              <div
                @click="hideText = !hideText"
                class="button button_blue mt-4 d-xl-none d-inline-flex"
              >
                {{ hideText ? "Читать подробнее" : "Скрыть" }}
              </div>
            </div>
          </div>
          <picture>
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/seemore/banner-3_mob.jpg`"
              media="(max-width: 767px)"
            />
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/seemore/banner-3_tab.jpg`"
              media="(max-width: 1219px)"
            />
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/seemore/banner-3.jpg`"
              media="(min-width: 1220px)"
            />
            <img
              class="third-banner__image"
              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/seemore/banner-3.jpg`"
            />
          </picture>
        </div>
        <div class="event-detail__main-info mt-8" id="programm">
          <div class="event-detail__about-title">
            Программа на 18 ноября 2023 г.
          </div>
          <div class="event-detail__tip">(указано московское время)</div>
          <InfoDrop
            v-for="(dropdown, ind) in program"
            :key="ind"
            @openInner="openInner(dropdown.title)"
            :item="{
              title: `<div class='programm-list__title'>${dropdown.title}</div>`,
              content: programmHTML(dropdown.items),
            }"
          />
        </div>
        <div class="hobl-activities mt-16">
          <div class="hobl-activities__title">
            В период ожидания эфира, мы предлагаем вам пройти несколько
            увлекательных активностей
          </div>

          <div class="hobl-activities__list">
            <div class="hobl-activities__item">
              <div class="hobl-activity__icon">
                <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/forsiga/icon1.svg`" />
              </div>
              <div
                class="hobl-activity__button button"
                @click="toWidget('find_word', 'Филворд')"
              >
                Филворд
              </div>
            </div>
            <!-- <div class="hobl-activities__item">
              <div class="hobl-activity__icon">
                <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/forsiga/icon5.svg`" />
              </div>
              <div
                class="hobl-activity__button button"
                @click="toWidget('true_false', 'Видеть_больше')"
              >
                Видеть больше
              </div>
            </div> -->
            <div class="hobl-activities__item">
              <div class="hobl-activity__icon">
                <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/forsiga/icon2.svg`" />
              </div>
              <div
                class="hobl-activity__button button"
                @click="toWidget('digital_quiz', 'Квиз')"
              >
                Квиз
              </div>
            </div>

            <div class="hobl-activities__item">
              <div class="hobl-activity__icon">
                <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/forsiga/icon3.svg`" />
              </div>
              <div
                class="hobl-activity__button button"
                @click="toWidget('feedback', 'Обратная_связь')"
              >
                Обратная связь
              </div>
            </div>
          </div>
        </div>
        <InfoDrop
          :item="{
            title: 'Список сокращений',
            content: shortsList,
          }"
        />
        <InfoDrop
          :item="{
            title: 'Список литературы',
            content: litList,
          }"
        />
      </div>
      <LibrarySlider
        v-if="sliderItems && sliderItems.length && !sliderItems.includes(null)"
        :items="sliderItems"
        :color="'#2186AF'"
        :title="'Это может быть полезным'"
        @clickMaterials="clickMaterials"
      />
    </div>
    <div v-else-if="EventDetail === 404"><Choch class="inPage" /></div>
    <div v-else-if="EventDetail === false"><Error class="inPage" /></div>
    <PopUpDefault @close="popupActivity = false" v-if="popupActivity">
      <div
        class="find_word"
        v-if="popupType === 'find_word'"
        id="otp_wgt_ei6lmn4pn7ih2"
      ></div>
      <div class="digital_quiz" v-if="popupType === 'digital_quiz'">
        <iframe
          style="max-width: 100%"
          src="https://wordwall.net/ru/embed/4e37326152f6434cb038881a3b012d65?themeId=1&templateId=5&fontStackId=0"
          width="100%"
          height="100%"
          frameborder="0"
          allowfullscreen
        ></iframe>
        <!-- <div
          data-tf-widget="fglcXkfl"
          data-tf-opacity="100"
          data-tf-hide-headers=""
          data-tf-inline-on-mobile=""
          data-tf-iframe-props='title=Квиз "Чем болели герои произведений?"'
          data-tf-medium="snippet"
          style="width: 100%; height: 100%"
        ></div> -->
      </div>
      <div class="true_false" v-if="popupType === 'true_false'">
        <SimpleCarousel :Slides="Slides"></SimpleCarousel>
      </div>
      <div class="feedback" v-if="popupType === 'feedback'">
        <iframe
          style="height: 100%; overflow: auto"
          width="100%"
          marginwidth="0"
          marginheight="0"
          frameborder="0"
          src="https://formdesigner.ru/form/iframe/205635"
        >
          <p>
            <a href="https://formdesigner.ru/form/view/205635"
              >https://formdesigner.ru/form/view/205635</a
            >
          </p>
        </iframe>
        <!-- <iframe
          src="https://forms.yandex.ru/u/64f1fa2e6938726fbc8aa0bd/?iframe=1"
          frameborder="0"
          name="ya-form-64f1fa2e6938726fbc8aa0bd"
          width="650"
        ></iframe> -->
      </div>
    </PopUpDefault>
    <Facecast
      :slug="'bolshe'"
      @closeFrame="openFrame = false"
      :open="openFrame"
    />
    <PopupEnd
      :eventName="'forsiga1811'"
      :backUrl="{ name: 'Seemore' }"
      @close="closePopup"
      v-if="popup"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Choch from "@/views/404.vue";
import Error from "@/views/Error.vue";
import PopUpDefault from "@/components/pageComponents/PopUpDefault.vue";
import SimpleCarousel from "@/components/pageComponents/SimpleCarousel.vue";
import DefaultSlider from "@/components/pageComponents/DefaultSlider.vue";
import InfoDrop from "../../components/pageComponents/InfoDrop.vue";
import LibrarySlider from "@/components/pageComponents/LibrarySlider.vue";
import Facecast from "@/components/pageComponents/Facecast.vue";
import PopupEnd from "@/components/pageComponents/PopUpEventEnd.vue";
export default {
  metaInfo() {
    if (this.EventDetail.name) {
      return {
        title: "Az-most - " + this.EventDetail.name.replace(/<[^>]*>?/gm, ""),
      };
    } else {
      return { title: "Az-most - " + this.EventDetail.name };
    }
  },
  name: "EventDetail",
  components: {
    Choch,
    Error,
    PopUpDefault,
    SimpleCarousel,
    DefaultSlider,
    InfoDrop,
    LibrarySlider,
    Facecast,
    PopupEnd,
  },
  data: () => ({
    hideText: true,
    popup: false,
    openFrame: false,
    popupActivity: false,
    popupType: "",
    timerResult: { id: 0 },
    timerResultEnd: {},
    settingsSlider: {
      slidesToShow: 4,
      draggable: false,
      infinite: true,
      responsive: [
        {
          breakpoint: 1220,
          settings: {
            slidesToShow: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            infinite: false,
            variableWidth: true,
          },
        },
      ],
    },
   
  }),
  computed: {
    ...mapGetters(["user", "TextList", "VideoList", "PodcastsList"]),
    Slides() {
       return [
      {
        outerHTML: `<img src="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/seemore/slides/sl1.png"/>`,
      },
      {
        outerHTML: `<img src="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/seemore/slides/sl2.png"/>`,
      },
      {
        outerHTML: `<img src="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/seemore/slides/sl3.png"/>`,
      },
      {
        outerHTML: `<img src="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/seemore/slides/sl4.png"/>`,
      },
    ]
    },
    sliderItems() {
      let arr = [];
      if (this.PodcastsList && this.PodcastsList.length) {
        arr = arr.concat(
          this.PodcastsList.map((el) => ({ ...el, page: "PodcastDetail" }))
        );
      }
      if (this.TextList && this.TextList.length) {
        arr = arr.concat(
          this.TextList.map((el) => ({ ...el, page: "TextDetail" }))
        );
      }
      if (this.VideoList && this.VideoList.length) {
        arr = arr.concat(
          this.VideoList.map((el) => ({ ...el, page: "VideoDetail" }))
        );
      }
      return arr;
    },
    shortsList() {
      return `<div class="short-list">СД 2 - сахарный диабет 2 типа; <br><br>ХСН - хроническая сердечная недостаточность; <br><br>ХБП - хроническая болезнь почек </div>`;
    },
    litList() {
      return `<ol><li>Nichols GA, et al. Diabetes Care 2004;27:1879–1884.</li><li>И.И. Дедов, М.В. Шестакова и соавторы.САХАРНЫЙ ДИАБЕТ В РОССИЙСКОЙ ФЕДЕРАЦИИ: ДИНАМИКА ЭПИДЕМИОЛОГИЧЕСКИХ ПОКАЗАТЕЛЕЙ ПО ДАННЫМ ФЕДЕРАЛЬНОГО РЕГИСТРА САХАРНОГО ДИАБЕТА ЗА ПЕРИОД 2010–2022 ГГ.Сахарный диабет. 2023;26(2):104-123.</li><li>Parving HH, et al. KidneyInt 2006;69:2057–2063.</li></ol>`;
    },
    lectors() {
      return [
        {
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/seemore/slides/slide1.png`,
          tag: "Спикер",
          name: "Анциферов Михаил Борисович",
          description:
            "д.м.н, профессор. Главный внештатный специалист эндокринолог Департамента здравоохранения города Москвы",
        },
        {
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/seemore/slides/slide2.png`,
          tag: "Спикер",
          name: "Галстян Гагик Радикович",
          description:
            "д.м.н., профессор, И.о. заместителя директора Института диабета. Заведующий отделением диабетической стопы ЭНЦ ГНЦ ФГБУ «НМИЦ эндокринологии» Минздрава России.",
        },
        {
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/seemore/slides/slide3.png`,
          tag: "Спикер",
          name: "Демидова Татьяна Юльевна",
          description:
            "д.м.н., профессор. Заведующая кафедрой эндокринологии РНИМУ им. Н.И. Пирогова.",
        },
        {
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/seemore/slides/slide4.png`,
          tag: "Спикер",
          name: "Викулова Ольга Константиновна",
          description:
            "д.м.н., Заведующая отделом эпидемиологии эндокринопатий ЭНЦ ГНЦ ФГБУ «НМИЦ эндокринологии» Минздрава России.",
        },
        {
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/seemore/slides/slide5.png`,
          tag: "Спикер",
          name: "Кобалава Жанна Давидовна",
          description:
            "д.м.н., профессор, член-корреспондент РАН. Заведующая кафедрой внутренних болезней и кардиологии им.В.С Моисеева медицинского института Российского университета дружбы народов имени Патриса Лумумбы.",
        },
        {
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/seemore/slides/slide6.png`,
          tag: "Спикер",
          name: "Мкртумян Ашот Мусаелович",
          description:
            "д.м.н, профессор,Заведующий кафедрой эндокринологии и диабетологии лечебного факультета МГМСУ им. А.И. Евдокимова. Руководитель научного отдела эндокринных и метаболических нарушений МКНЦ имени А.С. Логинова",
        },
        {
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/seemore/slides/slide7.png`,
          tag: "Спикер",
          name: "Шамхалова Минара Шамхаловна",
          description:
            "д.м.н., профессор, Заведующая отделением диабетической болезни почек и посттрансплантационной реабилитации ЭНЦ ГНЦ ФГБУ «НМИЦ эндокринологии» Минздрава России.",
        },
        {
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/seemore/slides/slide8.png`,
          tag: "Спикер",
          name: "Петунина Нина Александровна",
          description:
            "д.м.н., профессор, член-корреспондент РАН, заведующая кафедрой эндокринологии ИКМ ФГАОУ ВО Первый МГМУ им. И.М. Сеченова Минздрава России, главный внештатный специалист эндокринолог Минздрава РФ в ЦФО",
        },
      ];
    },
    EventDetail() {
      return {
        id: 83,
        types: [
          {
            id: 1,
            name: "Онлайн",
            code: "online",
          },
          {
            id: 2,
            name: "Офлайн",
            code: "offline",
          },
        ],
        lector: [],
        name: "Видеть больше",
        description: "",
        banner_description: "",
        additional_info: "",
        date: this.$route.meta.date || "2023-11-18T10:00:17+03:00",
        date_end: "2023-11-18T14:30:23+03:00",
        place:
          "г. Москва, конгресс-центр GRAND BALLROOM,<br> Шлюзовая набережная, дом 2/1, строение 1",
        hash: "18a4008489fb47ff0bdba42de43203ab86de5dc70744c0552f63e10912b49454",
        webinar_id: "",
        room_id: "",
        external: false,
        external_link: "",
        is_active: true,
        banner_mobile: null,
        banner_tablet: null,
        banner_desktop: null,
        banner_desktop_l: null,
        banner_desktop_xl: null,
        banner_desktop_xxl: null,
      };
    },
    program() {
      return [
        {
          title:
            "Глава 1 – ранняя интенсификация терапии пациента с СД 2 типа:",
          items: [
            {
              time: "10:00 – 10:10",
              title: "Открытие",
              description: "",
              icon: "talking.png",
            },
            {
              time: "10:10 – 10:15",
              title: "Вступительное слово",
              description:
                "Анциферов Михаил Борисович, д.м.н., профессор. Главный внештатный специалист эндокринолог Департамента здравоохранения города Москвы",
              icon: "anciferov.png",
            },
            {
              time: "10:15 – 10:30",
              title:
                "«Лучше на три часа раньше, чем на минуту позже. Ранняя интенсификация терапии СД 2 типа»",
              description:
                "Выступление профессора, д.м.н. Демидовой Татьяны Юльевны, вдохновленное произведениями Уильяма Шекспира",
              icon: "demidova.png",
            },
            {
              time: "10:30 – 10:45",
              title:
                "За безопасность необходимо платить, а за ее отсутствие расплачиваться. Ключевые аспекты безопасности применения иНГЛТ-2»",
              icon: "markova.png",
              description:
                "Выступление профессора, д.м.н. Марковой Татьяны Николаевны, вдохновленное известными цитатами Уинстона Черчилля",
            },
            {
              time: "10:45 – 11:00",
              title:
                "«Знания и опыт полезны для врача. Данные Московского регистра»",
              icon: "demidov.png",
              description:
                "Выступление к.м.н. Демидова Николая Александровича, вдохновленное учениями Парацельса",
            },
            {
              time: "11:00 – 11:15",
              title: "Дискуссия, ответы на вопросы аудитории",
              description: "",
              icon: "talking.png",
            },
            {
              time: "11:15 – 11:45",
              title: "Перерыв",
              description: "",
              icon: "coffee.png",
            },
          ],
        },
        {
          title: "Глава 2 – тонкости ведения пациентов с ХБП",
          items: [
            {
              time: "11:45 - 11:50",
              title: "Вступительное слово ",
              description:
                "Галстян Гагик Радикович, д.м.н., профессор, и.о. заместителя директора Института диабета. Заведующий отделением диабетической стопы ЭНЦ ГНЦ ФГБУ «НМИЦ эндокринологии» Минздрава России.",
              icon: "galstyan.png",
            },
            {
              time: "11:50 – 12:15",
              title:
                "«Данные реальной клинической практики для улучшения помощи пациентов с СД 2 типа»",
              description: "профессор Линонг Цзи",
              icon: "linong.png",
            },
            {
              time: "12:15 – 12:30",
              title:
                "«Зная прошлое, врач может предвидеть будущее. Регистр сахарного диабета как инструмент прогнозирования и контроля терапии ХБП»",
              description:
                "Выступление д.м.н. Викуловой Ольги Константиновны, вдохновленное учениями Гиппократа",
              icon: "vikulova.png",
            },
            {
              time: "12:30 – 12:45",
              title:
                "«Мы ответственны не только за то, что делаем, но и за то, что не делаем. Фокус на нефропротекцию у пациентов с СД 2 типа»",
              icon: "shamhalova.png",
              description:
                "Выступление профессора, д.м.н. Шамхаловой Минары Шамхаловны, вдохновленное произведениями Мольера",
            },
            {
              time: "12:45 – 13:00",
              title: "Дискуссия, ответы на вопросы аудитории",
              icon: "talking.png",
              description: "",
            },
            {
              time: "13:00 – 13:15",
              title: "Перерыв",
              description: "",
              icon: "coffee.png",
            },
          ],
        },
        {
          title: "Глава 3 – современный подход к терапии ХСН",
          items: [
            {
              time: "13:15 – 13:20",
              title: "Вступительное слово ",
              description:
                "Петунина Нина Александровна, д.м.н., профессор, член-корреспондент РАН, заведующая кафедрой эндокринологии ИКМ ФГАОУ ВО Первый МГМУ им. И.М. Сеченова Минздрава России, главный внештатный специалист эндокринолог Минздрава РФ в ЦФО",
              icon: "petunina.png",
            },
            {
              time: "13:20 – 13:50",
              title:
                "«Способность предвидеть развитие болезни - лучшее качество врача - от этого зависит успех. Диагностика ХСН у пациентов с СД 2 типа»",
              description:
                "Выступление член-кор. РАН Кобалавы Жанны Давидовны, вдохновленное учениями Эдмунда Гуссерля",
              icon: "kobalava.png",
            },
            {
              time: "13:50 – 14:05",
              title:
                "«Сложнее всего начать действовать - все остальное зависит только от упорства. Прогнозы терапии в новой реальности у пациента с СД 2»",
              description:
                "Выступление проф., д.м.н. Мкртумяна Ашота Мусаеловича, вдохновленное работами Амелии Эрхарт",
              icon: "mkrtumyan.png",
            },
            {
              time: "14:05 – 14:20",
              title: "Дискуссия, ответы на вопросы аудитории",
              icon: "talking.png",
              description: "",
            },
          ],
        },
      ];
    },
  },
  methods: {
    ...mapActions([
      "fetchEventsCalendar",
      "fetchTextMaterials",
      "fetchVideoMaterials",
      "fetchPodcasts",
      "fetchAlerts",
    ]),
    openIframe() {
      const vm = this;
      if (!localStorage.getItem("token") && !!!Object.keys(this.user).length) {
        if (!!!this.$route.query.access) {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "bolshe", {
              bolshe: {
                "broadcast watch": {
                  ...vm.$root.ymFields,
                },
              },
            });
          }
          // this.openFrame = true;
          this.popup = true;
        } else {
          // this.openFrame = true;
          this.popup = true;
        }
        // this.openFrame = true;
      } else {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "bolshe", {
            bolshe: {
              "broadcast watch": {
                ...vm.$root.ymFields,
              },
            },
          });
        }
        this.openFrame = true;
      }
    },
    copyLink() {
      var copyText = `${window.location.href}`;
      navigator.clipboard.writeText(copyText);
      this.fetchAlerts({
        alerts: "Ссылка скопирована в буфер обмена",
        type: "notify",
      });
    },
    scrollTo(selector) {
      if (selector === "programm") {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "bolshe", {
            bolshe: {
              "programm click": {
                ...this.$root.ymFields,
              },
            },
          });
        }
      }
      this.activeTab = parseInt(selector.replace(/^\D+/g, "")) - 1;
      const section = document.querySelector("#" + selector);

      // определяем отступ от верхнего края всей страницы (не области просмотра)
      const multyplier = this.$root.isMobile
        ? window.innerHeight
        : window.innerHeight / 2;
      const position = Math.round(section.offsetTop) + multyplier;

      // прокручиваем область просмотра до нужной позиции
      scrollTo({
        top: position,
        // плавно
        behavior: "smooth",
      });
    },
    programmHTML(items) {
      let html = ``;
      let block = ``;
      for (let item of items) {
        block += `
              <div class="hobl__program-item">
                <div class="hobl__program-time">${item.time}</div>
                <div class="d-flex align-start hobl__program-info">
                  <img class="mr-4" src="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/seemore/icons/${item.icon}"  />
                  <div class="hobl__program-info my-auto">
                    <div class="hobl__program-title">
                    ${item.title}
                    </div>
                    <div style="${
                      item.description ? "" : "display: none"
                    }" class="hobl__program-description">
                      ${item.description}
                    </div>
                  </div>
                </div>
              </div>
            </div>`;
      }
      html = `<div class="hobl__program mb-8">${block}</div>`;
      return html;
    },
    clickMaterials(name) {
      const vm = this;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "bolshe", {
          bolshe: {
            "material click": {
              [name]: {
                ...this.$root.ymFields,
              },
            },
          },
        });
      }
    },
    async toRegister(reg_button) {
      if (reg_button) {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "bolshe", {
            bolshe: {
              "reg click": {
                ...this.$root.ymFields,
              },
            },
          });
        }
      } else {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "bolshe", {
            bolshe: {
              "join click": {
                ...this.$root.ymFields,
              },
            },
          });
        }
      }
      localStorage.setItem("source", `forsiga1811`);
      localStorage.setItem("backUrl", JSON.stringify({ name: "SeeMore" }));
      // const route = this.$router.resolve({
      //   name: "Register",
      //   query: { email: this.$route.query.email || "" },
      // });
      // window.open(route.href, "_blank");
      window.open(this.$root.ssoLinks.registration, "_self");
    },
    openInner(title) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "bolshe", {
          bolshe: {
            "programm block click": {
              [title.split("–")[0]]: {
                ...this.$root.ymFields,
              },
            },
          },
        });
      }
    },
    timer() {
      let date = new Date();
      const dateEnd = this.$m
        .utc(this.EventDetail.date)
        .utcOffset(+3)
        .subtract(10, "minutes");
      const today = this.$m.utc(date).utcOffset(+3);
      const diff = today.diff(dateEnd, "seconds");
      this.$set(this, "timerResult", {});
      if (diff > 0) return;
      let months = Math.abs(diff) / 2592000;
      let fullMonths = Math.floor(months);
      let days = (months - Math.floor(months)) * 30;
      let fullDays = Math.floor(days);
      let hours = (days - Math.floor(days)) * 24;
      let fullHours = Math.floor(hours);
      let minutes = (hours - Math.floor(hours)) * 60;
      let fullMinutes = Math.floor(minutes);
      let seconds = (minutes - Math.floor(minutes)) * 60;
      let fullSeconds = Math.round(seconds);
      this.$set(this, "timerResult", {});
      if (fullMonths) {
        this.$set(this.timerResult, "months", fullMonths);
      }
      if (fullDays) {
        this.$set(this.timerResult, "days", fullDays);
      }
      if (fullHours) {
        this.$set(this.timerResult, "hours", fullHours);
      }
      if (fullMinutes) {
        this.$set(this.timerResult, "minutes", fullMinutes);
      }
      if (fullSeconds) {
        this.$set(this.timerResult, "seconds", fullSeconds);
      }
    },
    async getCalendar(id) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "forsiga", {
          forsiga: {
            "add to calendar": {
              ...this.$root.ymFields,
            },
          },
        });
      }
      const url = await this.fetchEventsCalendar(id);
      let a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);
      a.href = url;
      a.download = "event.ics";
      a.click();
      setTimeout(async () => {
        window.URL.revokeObjectURL(url);
      }, 1000);
      a.remove();
    },
    
    
    closePopup() {
      this.popup = false;
    },
    findWord(w, d, n, s, rp) {
      let otp_wjs_dt = new Date().getTime();
      w[n] = w[n] || [];
      rp = {};
      w[n].push(function () {
        otp_render_widget(
          d.getElementById("otp_wgt_ei6lmn4pn7ih2"),
          "onlinetestpad.com",
          "ei6lmn4pn7ih2",
          rp
        );
      });
      s = d.createElement("script");
      s.type = "text/javascript";
      s.src = "//onlinetestpad.com/js/widget.js?" + otp_wjs_dt;
      s.async = true;
      d.getElementsByTagName("head")[0].appendChild(s);
    },
    digitalQuiz() {
      let s = document.createElement("script");
      s.type = "text/javascript";
      s.src = "//embed.typeform.com/next/embed.js";
      s.async = true;
      document.getElementsByTagName("head")[0].appendChild(s);
    },
    feedback() {
      let s = document.createElement("script");
      s.type = "text/javascript";
      s.src = "//yastatic.net/s3/frontend/forms/_/embed.js";
      s.async = true;
      document.getElementsByTagName("head")[0].appendChild(s);
    },
    toWidget(type, name) {
      const vm = this;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "bolshe", {
          bolshe: {
            "game click": {
              [name]: {
                ...this.$root.ymFields,
              },
            },
          },
        });
      }
      this.popupActivity = true;
      this.popupType = type;
      if (type === "find_word") {
        this.findWord(window, window.document, "otp_widget_callbacks");
      }
      if (type === "digital_quiz") {
        this.digitalQuiz();
      }
      if (type === "feedback") {
        this.feedback();
      }
    },
  },
  created() {
    this.timer();
    this.interval = setInterval(this.timer, 1000);
  },
  async mounted() {
    const vm = this;

    await this.fetchTextMaterials({
      disease: ["hbp", "hsn", "diabetes"],
    });
    await this.fetchVideoMaterials({
      disease: ["hbp", "hsn", "diabetes"],
    });
    await this.fetchPodcasts({
      disease: ["hbp", "hsn", "diabetes"],
    });
    
    window.addEventListener("message", this.webinarEnd, false);
    let video = document.querySelector(".event-detail__timer video");
    if (video) {
      video.addEventListener("play", function () {
        if (this.currentTime === 0) {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "video start", {
              "video start": {
                "video name": vm.EventDetail.name,
                "video duration": Math.round(this.duration),
                ...vm.$root.ymFields,
              },
            });
          }
        } else {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "video resume", {
              "video resume": {
                "video name": vm.EventDetail.name,
                "video duration": Math.round(this.duration),
                ...vm.$root.ymFields,
              },
            });
          }
        }
        vm.cyclicVideo(Math.round(this.duration));
      });
      video.addEventListener("pause", function () {
        if (this.currentTime !== this.duration) {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "video pause", {
              "video pause": {
                "video name": vm.EventDetail.name,
                "video duration": Math.round(this.duration),
                ...vm.$root.ymFields,
              },
            });
          }
        }
        clearTimeout(vm.timer);
        vm.timer = null;
      });
      video.addEventListener("ended", function () {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "video end", {
            "video end": {
              "video name": vm.EventDetail.name,
              "video duration": Math.round(this.duration),
              ...vm.$root.ymFields,
            },
          });
        }
      });
      video.addEventListener("timeupdate", function () {
        let percent = Math.ceil((this.currentTime / this.duration) * 100);
        if (this.percent !== percent && percent !== 0 && percent % 5 === 0) {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "video percentage watched", {
              "video percentage watched": {
                "video percentage watched": percent,
                ...vm.$root.ymFields,
              },
            });
          }
          this.percent = percent;
        }
      });
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
    window.removeEventListener("message", this.webinarEnd, false);
  },
  watch: {
    "$route.params.id": {
      handler: async function () {},
      deep: true,
      immediate: true,
    },
    async timerResult() {
      if (!Object.keys(this.timerResult).length) {
        clearInterval(this.interval);
      }
    },
    async EventDetail() {
      if (this.EventDetail.external) {
        window.open(this.EventDetail.external_link, "_self");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.popup__text {
  font-family: "Roboto Slab", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  color: #656969;
}
.timer {
  &__value {
    margin-right: 16px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;

    @media screen and (max-width: 767px) {
      margin-right: 0;
      font-size: 32px;
      line-height: 38px;
    }
  }

  &__text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
.event-detail {
  padding-top: 64px;
  @media screen and (max-width: 1219px) {
    padding-top: 40px;
  }

  video {
    width: 100%;
    height: auto;
  }

  &__banner {
    width: 100%;
  }

  &__timer {
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 37px;
    background-color: #d0006f;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #fff;
    position: relative;

    @media screen and (max-width: 1219px) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media screen and (max-width: 767px) {
      padding: 16px;
    }

    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      margin-right: auto;

      @media screen and (max-width: 1219px) {
        margin-right: 0;
        margin-bottom: 32px;
      }

      @media screen and (max-width: 767px) {
        font-size: 21px;
        line-height: 26px;
      }
    }

    &-item {
      margin-right: 24px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &:last-child {
        margin-right: 0;
      }

      @media screen and (max-width: 1219px) {
        margin-right: auto;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-right: auto;
      }
    }

    &-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media screen and (max-width: 1219px) {
        width: 100%;
      }
    }

    &-date {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 42px;
      line-height: 48px;
      color: #ffffff;

      @media screen and (max-width: 767px) {
        font-size: 26px;
      }
    }
  }

  &__iframe {
    position: relative;
    width: 100%;
    display: none;

    & iframe {
      width: 100%;
      height: 800px;

      @media screen and (max-width: 1219px) {
        width: 100%;
        height: 100%;
      }
    }

    @media screen and (max-width: 1219px) {
      display: none;
    }
  }

  &__iframe_opened {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    padding: 64px;

    & iframe {
      height: 100%;
    }

    @media screen and (max-width: 1219px) {
      padding: 0;
    }
  }

  &__close-btn {
    cursor: pointer;
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 2;

    @media screen and (max-width: 1219px) {
      top: 0;
      right: 50%;
      transform: translateX(50%);
    }
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__translation {
    max-width: 280px;

    @media screen and (max-width: 1219px) {
      width: auto;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: none;
    }
  }

  &__add-calendar {
    width: 280px;
    margin-left: auto;
    @media screen and (max-width: 1219px) {
      width: 100%;
    }
  }
}

.features-grid {
  margin-top: 56px;
  @media screen and (max-width: 1219px) {
    display: none;
  }
  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 33px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 181px;
    border: 2px solid;
    border-radius: 16px;
    padding: 18px 32px;
    &-title {
      margin-bottom: 8px;
      font-family: "TT Rationalist Trl", sans-serif;
      font-size: 48px;
      font-weight: 600;
      line-height: 59px;
      &.blue-item {
        color: #1b98d5;
      }
      &.red-item {
        color: #dd2726;
      }
      &.green-item {
        color: #c9da2b;
      }
    }
    &.blue-item {
      border-color: #1b98d5;
    }
    &.red-item {
      border-color: #dd2726;
    }
    &.green-item {
      border-color: #c9da2b;
    }
    &__descr {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #1f1f1f;
    }
  }
}
</style>

<style lang="scss" scoped>
.first-banner {
  overflow: hidden;
  height: 419px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1219px) {
    flex-direction: column;
    align-items: stretch;
    height: auto;
  }

  @media screen and (max-width: 767px) {
    border-radius: 0;
  }

  &__content {
    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }

  &__title {
    color: #1f1f1f;
    font-family: "Roboto Slab", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    margin-bottom: 16px;
    @media screen and (max-width: 1219px) {
      & br {
        display: none;
      }
    }
    @media screen and (max-width: 767px) {
      font-size: 32px;
      line-height: 38px;
    }
  }
  &__description {
    margin-bottom: 24px;
    color: #1f1f1f;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    @media screen and (max-width: 1219px) {
      display: none;
    }
  }

  &__button {
    width: 200px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 16px;
    }
  }

  &__text {
    position: relative;
    z-index: 2;
    padding-left: 74px;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    @media screen and (max-width: 1219px) {
      position: static;
      max-width: none;
      margin-top: 16px;
      padding: 0;
    }

    @media screen and (max-width: 767px) {
      background-color: #dee2ee;
      padding: 24px 16px;
      display: flex;
      margin-top: 0;
      flex-direction: column-reverse;
    }
  }

  & picture {
    width: auto;
    height: 100%;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
    }
  }

  &__image {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
      position: relative;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }
}
.second-banner {
  position: relative;
  overflow: hidden;
  height: 340px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1219px) {
    flex-direction: column;
    align-items: stretch;
    height: auto;
    border-radius: 16px;
  }

  @media screen and (max-width: 767px) {
    border-radius: 16px;
  }

  &__title {
    color: #1f1f1f;
    font-family: "Roboto Slab", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    margin-bottom: 16px;
    @media screen and (max-width: 767px) {
      font-size: 21px;
      line-height: 26px;
    }
  }
  &__description {
    margin-bottom: 24px;
    color: #1f1f1f;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  &__button {
    &-container {
      display: flex;
      flex-direction: row;
    }
    width: fit-content;
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }

  &__text {
    position: relative;
    z-index: 2;
    padding-left: 74px;
    max-width: 589px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    @media screen and (max-width: 1219px) {
      padding: 24px 16px;
      position: static;
      max-width: none;
      background-color: #dee2ee;
    }
  }

  & picture {
    width: auto;
    height: 100%;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
    }
  }

  &__image {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
      position: relative;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }
}
.third-banner {
  margin-top: 64px;
  position: relative;
  overflow: hidden;
  height: 485px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1219px) {
    flex-direction: column;
    align-items: stretch;
    height: auto;
  }

  @media screen and (max-width: 1219px) {
    border-radius: 16px;
    margin-top: 32px;
  }

  &__title {
    color: #1f1f1f;
    font-family: "Roboto Slab", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    margin-bottom: 24px;

    @media screen and (max-width: 767px) {
      font-size: 21px;
      line-height: 26px;
    }
  }
  &__description {
    margin-bottom: 24px;
    color: #1f1f1f;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  &__button {
    &-container {
      display: flex;
      flex-direction: row;
    }
    width: fit-content;
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }

  &__text {
    position: relative;
    z-index: 2;
    padding-left: 74px;
    max-width: 589px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    @media screen and (max-width: 1219px) {
      padding: 24px 16px;
      background-color: #dee2ee;
      position: static;
      max-width: none;
    }
  }

  & picture {
    width: auto;
    height: 100%;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
    }
  }

  &__image {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
      position: relative;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }
}
</style>

<style lang="scss">
.hobl {
  &__program {
    &-item {
      margin-bottom: 48px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      &:last-child {
        margin-bottom: 0;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }

    &-time {
      width: fit-content;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #1b98d5;
      padding: 4px 8px;
      border-radius: 99px;
      // background-color: #634075;
      background-color: #f8f8f8;
      margin-right: auto;

      @media screen and (max-width: 767px) {
        margin-bottom: 16px;
      }
    }

    &-info {
      width: 75%;

      & > img {
        width: 50px;
        height: 50px;
        margin-right: 15px;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    &-title {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      // color: #ffffff;
      color: #3c4242;
    }

    &-description {
      margin-top: 8px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      // color: #b2b4b4;
      color: #656969;
    }
  }

  &-activities {
    &__title {
      margin-bottom: 24px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      color: #1f1f1f;
    }

    &__description {
      margin-bottom: 24px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #3c4242;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 32px;
      row-gap: 32px;

      @media screen and (max-width: 1219px) {
        grid-template-columns: repeat(4, 260px);
        overflow-x: auto;
      }

      @media screen and (max-width: 767px) {
        grid-template-columns: repeat(4, 280px);
      }
    }

    &__item {
      padding: 40px 22px;
      background: #f8f8f8;
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      flex-shrink: 0;
    }
  }

  &-activity {
    &__icon {
      height: 117px;
      margin-bottom: 32px;

      & img {
        height: 100%;
        max-width: 177px;
      }
    }

    &__button {
      width: 100%;
      border: 1px solid #2186af;
      color: #2186af;
      background-color: transparent;

      &:hover {
        border-color: #2eb5eb;
        color: #2eb5eb;
      }
    }
  }
}
.event-detail {
  &__warning {
    padding: 16px;
    margin-bottom: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    border-radius: 16px;
    background-color: #1b98d5;

    & svg {
      flex-shrink: 0;
    }

    & a {
      text-decoration: underline;
    }
  }
  i {
    font-style: italic !important;
  }

  b,
  strong {
    font-weight: 500 !important;
  }

  ul {
    list-style: disc;
    padding-left: 18px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  ol {
    list-style: decimal;
    padding-left: 18px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  li {
    margin-bottom: 8px;

    & > ul {
      margin-top: 8px;

      & li {
        color: #656969;
        list-style-type: none;
      }
    }
  }
}
</style>

<style lang="scss">
.find_word {
  margin: 0 auto;

  width: 900px;
  height: 675px;
  @media screen and (max-width: 1219px) {
    width: 100%;
    height: 675px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 825px;
  }
}

.digital_quiz {
  margin: 0 auto;

  width: 1000px;
  height: 600px;
  @media screen and (max-width: 1219px) {
    width: 100%;
    height: 550px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 500px;
  }
}

.true_false {
  margin: 0 auto;

  width: 1000px;
  height: 600px;
  @media screen and (max-width: 1219px) {
    width: 100%;
    height: 550px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 500px;
  }
}

.seemore + .modal-popup .modal-popup__content {
  max-width: fit-content;
}

.true_false iframe {
  margin: 0 auto;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 1219px) {
    width: 100%;
    // height: 500px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    // height: 300px;
  }
}

.feedback iframe {
  margin: 0 auto;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 1219px) {
    width: 100%;
    // height: 500px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    // height: 300px;
  }
}

.feedback {
  margin: 0 auto;

  width: 1000px;
  height: 600px;
  overflow-y: auto;
  @media screen and (max-width: 1219px) {
    width: 100%;
    height: 550px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 500px;
  }
}

.short-list {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #656969;
}
</style>

<style lang="scss" scoped>
.popup__text {
  font-family: "Roboto Slab", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  color: #656969;
}
.timer {
  &__value {
    margin-right: 16px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;

    @media screen and (max-width: 767px) {
      margin-right: 0;
      font-size: 32px;
      line-height: 38px;
    }
  }

  &__text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
.event-detail {
  padding-top: 64px;
  @media screen and (max-width: 1219px) {
    padding-top: 40px;
  }

  &__tip {
    margin-top: 8px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #b2b4b4;
  }

  &__description-block {
    margin-bottom: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 48px;
    background-color: #f8f8f8;

    & img {
      height: 220px;
      @media screen and (max-width: 1219px) {
        width: 100%;
        height: auto;
      }

      @media screen and (max-width: 767px) {
        margin: 0 auto;
      }
    }

    @media screen and (max-width: 1219px) {
      display: block;
      padding: 24px;
    }

    @media screen and (max-width: 767px) {
      display: block;
      padding: 16px;
    }
  }

  video {
    width: 100%;
    height: auto;
  }

  &__banner {
    width: 100%;
  }

  &__timer {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 37px;
    border-radius: 16px;
    background: #1b98d5;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #fff;
    position: relative;

    @media screen and (max-width: 1219px) {
      flex-direction: column;
      align-items: stretch;
    }

    @media screen and (max-width: 767px) {
      padding: 16px;
    }

    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      margin-right: auto;

      @media screen and (max-width: 1219px) {
        margin-right: 0;
        margin-bottom: 32px;
      }

      @media screen and (max-width: 767px) {
        font-size: 21px;
        line-height: 26px;
      }
    }

    &-item {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-right: 24px;
      &:last-child {
        margin-right: 0;
        &::after {
          display: none;
        }
      }

      &::after {
        display: block;
        content: "";
        width: 1px;
        height: 50%;
        background-color: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }

      @media screen and (max-width: 1219px) {
        padding: 0 15px;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        padding: 0 15px;
      }
    }

    &-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media screen and (max-width: 1219px) {
        // width: 100%;
      }
    }

    &-date {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 42px;
      line-height: 48px;
      color: #ffffff;

      @media screen and (max-width: 767px) {
        font-size: 26px;
      }
    }
  }

  .translation-counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    @media screen and (max-width: 1219px) {
      padding-top: 16px;
      margin-top: 16px;
      border-top: 1px solid #fff;
    }
    &__num {
      font-family: "Roboto Slab", sans-serif;
      font-size: 48px;
      font-weight: 400;
      line-height: 62px;

      @media screen and (max-width: 767px) {
        font-size: 40px;
      }
    }
    &__text {
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;

      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
    &__img {
      width: 90px;
      height: 42px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__iframe {
    position: relative;
    width: 100%;
    display: none;

    & iframe {
      width: 100%;
      height: 800px;

      @media screen and (max-width: 1219px) {
        width: 100%;
        height: 100%;
      }
    }

    @media screen and (max-width: 1219px) {
      display: none;
    }
  }

  &__iframe_opened {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    padding: 64px;

    & iframe {
      height: 100%;
    }

    @media screen and (max-width: 1219px) {
      padding: 0;
    }
  }

  &__close-btn {
    cursor: pointer;
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 2;

    @media screen and (max-width: 1219px) {
      top: 0;
      right: 50%;
      transform: translateX(50%);
    }
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__translation {
    max-width: 280px;

    @media screen and (max-width: 1219px) {
      width: auto;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: none;
    }
  }

  &__date-info {
    border-left: 4px solid #0194ac;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 24px;
    margin-top: 32px;
    margin-bottom: 64px;
    background-color: #f8f8f8;
    border-top: 1px solid #ebefee;
    border-right: 1px solid #ebefee;
    border-bottom: 1px solid #ebefee;

    @media screen and (max-width: 1219px) {
      flex-direction: column;
      margin-top: 40px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 32px;
      margin-bottom: 24px;
    }
  }

  &__about-title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #3c4242;
  }

  &__about-description {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #656969;

    @media screen and (max-width: 1219px) {
      display: none;
    }
  }

  &__date-text {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 1219px) {
      flex-direction: column;
    }
  }

  &__date-button {
    margin-top: 14px;
    width: 192px;
  }

  &__date-item {
    margin-right: 32px;

    @media screen and (max-width: 1219px) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }

  &__date-item-title {
    margin-bottom: 4px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #b2b4b4;
  }

  &__date-item-value {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  &__add-calendar {
    width: 280px;
    margin-left: auto;
    @media screen and (max-width: 1219px) {
      width: 100%;
    }
  }
}

.seemore {
  @media screen and (max-width: 767px) {
    padding-top: 0;
  }
}
</style>

<style lang="scss" scoped>
.slide-lector {
  // padding: 24px;
  border-radius: 16px;
  height: 100%;

  &__image-container {
    width: 280px;
    height: 270px;
    margin-bottom: 16px;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  &__tag {
    margin-bottom: 16px;
    border-radius: 8px;
    padding: 4px 8px;
    background-color: #2186af;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #fff;
    width: fit-content;
  }

  &__name {
    margin-bottom: 8px;
    color: #3c4242;
    font-family: "Roboto Slab", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  &__description {
    color: #b2b4b4;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
}

.default-slider {
  padding: 24px 0;
}
</style>

<style lang="scss">
.seemore .default-slider__arrows {
  @media screen and (max-width: 767px) {
    display: none !important;
  }
}

.seemore .programm-list__title {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #1b98d5;
}
</style>
